export const dateFormats = {
  americanMonthAbbr: 'MMM DD YYYY',
  americanMonthFull: 'MMMM DD YYYY',
  americanShort: 'MM.DD.YYYY',
  europeanMonthAbbr: 'DD MMM YYYY',
  europeanMonthFull: 'DD MMMM YYYY',
  europeanShort: 'DD.MM.YYYY',
  reversed: 'YYYY-MM-DD',
  timeShort: 'HH:mm',
};
