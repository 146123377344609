import { CircularProgress } from '@material-ui/core';

import { useStyles } from './LoadingIndicator.styles';

export interface LoadingIndicatorProps {
  isRelative?: boolean;
}

export const LoadingIndicator = ({ isRelative = true }: LoadingIndicatorProps) => {
  const classes = useStyles({ isRelative });

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <CircularProgress color="primary" />
      </div>
    </div>
  );
};
