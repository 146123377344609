/* eslint-disable sort-keys */
export const colorTheme = {
  error: {
    light: '#FBF3F5',
    main: '#b8123c',
  },
  primary: {
    light: '#FFA680',
    main: '#FF4E00',
  },
  secondary: {
    light: '#E1E1E1',
    main: '#808080',
  },
  success: {
    light: '#83BC80',
    main: '#087900',
  },
  warning: {
    contrastText: '#ffffff',
    light: '#FFCC80',
    main: '#FF9900',
  },
  greyScale: {
    darkGrey: '#404040',
    grey01: '#F2F2F2',
    grey02: '#E1E1E1',
    grey03: '#BFBFBF',
    mediumGrey: '#808080',
  },
  grey: {
    100: '#404040',
    75: '#808080',
    50: '#BFBFBF',
    25: '#E1E1E1',
    5: '#F2F2F2',
  },
  orangeScale: {
    100: '#FF4E00',
    75: '#FF7A40',
    50: '#FFA680',
    25: '#FFD3BF',
    5: '#FFF6F2',
  },
  greenScale: {
    100: '#087900',
    75: '#469A40',
    50: '#83BC80',
    25: '#C1DDBF',
    5: '#F3F8F2',
  },
  yellowScale: {
    100: '#FF9900',
    75: '#FFB240',
    50: '#FFCC80',
    25: '#FFE5BF',
    5: '#FFFAF2',
  },
  redScale: {
    100: '#B8123C',
    75: '#CA4D6D',
    50: '#DB889D',
    25: '#EDC4CE',
    5: '#FBF3F5',
  },
} as const;
