import {
  makeStyles,
  Theme,
} from '@material-ui/core';

interface StylesProps {
  isRelative: boolean;
}

export const useStyles = makeStyles<Theme, StylesProps>(() => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100px',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  wrapper: {
    position: ({ isRelative }) => (isRelative ? 'relative' : 'initial'),
  },
}));
